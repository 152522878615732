// Tweets.js

import React, { useEffect, useRef, useState } from 'react';
import "./style.css";
import { Slide } from 'react-awesome-reveal';

import MobileCases from './mobile';
import { Book, chartbar, clock } from '../../../Assets/svgIcons';
import { androidLink } from '../../../data/applicationLinks';

const CasesTwo = ({ setComponent }) => {
  const componentRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.4,
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setComponent(<MobileCases />);
      }
    });
  };

  return (
    <div className="features" ref={componentRef}>
      <div className="banner_content">
        <Slide direction="left">
          <div className="left_banner">
            <section className="mcq-features-section">
              <div className="section-header">
                <h2>Deepen Understanding with Case Explains</h2>
              </div>
              <div className="illustration">
                <img
                  src={require("../../../Assets/images/case_explains.png")}
                  alt="Illustration"
                />
              </div>
              <div className="feature-icons">
                {Book}{" "}
                {/* Assuming you have an icon for a book or case study */}
                {clock} {/* Clock icon for time management */}
                {chartbar} {/* Chart bar icon for progress tracking */}
              </div>
              <div className="text-description">
                <p>
                  Enhance your learning through detailed case explains. Delve
                  into real-world scenarios, express your insights, and receive
                  valuable feedback for comprehensive understanding.
                </p>
              </div>
              <div className="sample-question">
                <p>
                  Immerse yourself in thought-provoking case studies that span a
                  diverse range of subjects. Acquire valuable insights and
                  refine your problem-solving skills through detailed
                  explanations.
                </p>
              </div>
              <div className="cta-button">
                <a href={androidLink} target="_blanck" className="cta-btn">
                  Start Exploring Cases
                </a>
              </div>
            </section>
          </div>
        </Slide>
        <div className="visibility">
          <MobileCases />
        </div>
      </div>
    </div>
  );
};

export default CasesTwo;
