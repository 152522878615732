import React from 'react';
// import './banner.css'
import { FaArrowLeft } from "react-icons/fa6";
const Banner = ({ setPageName }) => {
  return (
    <div className="banner_menu">
      <h3 style={{marginBottom:'10px'}}>
        <span>General Surgery</span>
        <FaArrowLeft
          onClick={() => {
            setPageName("cassestopics");
          }}
        />
      </h3>
      <h4>All Topics</h4>
      <div className="re_keyword">
        <div className="left">
          <img
            src={require("../../../../../../Assets/images/problem.png")}
            alt=""
          />
          <p style={{color:'white'}}>Report a Problem</p>
        </div>
        <div className="right">
          <img
            src={require("../../../../../../Assets/images/key.png")}
            alt=""
          />
          <p style={{color:'white'}}>keywords</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
