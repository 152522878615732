import React, { useEffect, useRef } from 'react';
import "./style.css";
import { Slide } from 'react-awesome-reveal';

import { Book, BookMark, ChartLine } from '../../../Assets/svgIcons';
import MobileFlashCards from './mobile';
import { InView } from 'react-intersection-observer';
import { androidLink } from '../../../data/applicationLinks';

const FlashCards = ({ setComponent }) => {
  const componentRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.4,
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setComponent(<MobileFlashCards />);
      }
    });
  };
  return (
    <InView
      as="div"
      // onChange={(inView, entry, ref) => setComponent(<MobileFlashCards />)}
    >
      <div className="features" ref={componentRef}>
        <div className="banner_content">
          <Slide direction="left">
            <div className="left_banner">
              <section class="flash-cards-section">
                <div class="section-header">
                  <h2>Master Concepts with Flash Cards</h2>
                </div>
                <div class="illustration">
                  <div class={"card-flid"}>
                    <div class="card-inner">
                      <div class="card-front">Question</div>

                      <div class="card-back">Answer</div>
                    </div>
                  </div>
                </div>
                <div class="feature-icons">
                  {Book}
                  {ChartLine}
                  {BookMark}
                </div>
                <div class="text-description">
                  <p>
                    Efficiently master concepts using our Flash Cards feature.
                    Personalize your study sessions, track your progress, and
                    enhance your learning experience.
                  </p>
                </div>
                <div class="sample-flash-card">
                  <p>
                    Explore a new way of learning with interactive flashcards.
                    Memorize key concepts, terms, and facts effortlessly.
                  </p>
                </div>

                <div class="cta-button">
                  <a href={androidLink} target="_blanck" class="cta-btn">
                    Start Flash Card Learning
                  </a>
                </div>
              </section>
            </div>
          </Slide>
          <div className="visibility">
            {" "}
            <MobileFlashCards />
          </div>
        </div>
      </div>
    </InView>
  );
};

export default FlashCards;
