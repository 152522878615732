import React from 'react';
import './cassesunit.css';
import { FaArrowLeftLong } from "react-icons/fa6";
import { unitsData } from '../../../Flash-Cards/SecondPage/FlashUnits/data';
import Unit from './unit/Unit';
const CassesUnits = ({ setPageName, setSelectedUnit }) => {
  return (
    <div className="tweetsunits">
      <h3>
        <div>
          <span>Casses</span>
        </div>
        <FaArrowLeftLong
          onClick={() => {
            setPageName("cassescourses");
          }}
        />
      </h3>
      <p>General Surgery</p>
      <div className="units">
        {unitsData.map((item, index) => {
          return (
            <Unit
              setSelectedUnit={setSelectedUnit}
              setPageName={setPageName}
              unitData={item}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CassesUnits;
