import React, { useEffect, useState } from 'react'
import './alltopics.css'
import { IoIosRefreshCircle } from "react-icons/io";
import { FaArrowLeft, FaArrowLeftLong } from 'react-icons/fa6';
import { topicsData } from './data';
import Topic from './Topic/Topic';
const AllTopics = ({setPageName,selectedUnit,setSelectedTopic}) => {
  const [topics,setTopics]=useState([]);
  const getTopics=()=>{
    setTopics(topicsData)
  }
  useEffect(()=>{
    getTopics()
  },[])
  return (
    <div className='topics_project_page'>
      <div className="header">
        <FaArrowLeft
          onClick={()=>{
            // console.log("ere")
            setPageName('unites');
          }}
        />
        <h4>Cards Topic</h4>
        <div>
          <IoIosRefreshCircle/>
          <h4>Refresh Topic</h4>
        </div>
      </div>
      <div className="text">
        <p>{selectedUnit.name}</p>
      </div>
      <div className="content">
        {
          topicsData.map((item,index)=>{
            return(
              <Topic setPageName={setPageName} key={index} topicData={item}/>
            )
          })
        }
      </div>
    </div>
  )
}

export default AllTopics
