import React from 'react';
import "./style.css";
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  EffectCards,
  Autoplay,
} from 'swiper/modules';
import SwiperCore from "swiper";

const Screens = ({ ref }) => {
  SwiperCore.use([Navigation, Pagination, Scrollbar, Pagination, Autoplay]);
  return (
    <div className="screens" id="preview">
      <div className="screens_content">
        <div className="breadCrumb">
          <h1 className="crumb">App Preview</h1>
          <p>Screen shots From Aplication</p>
        </div>
        <div className="silder_component">
          <Swiper
            // effect="coverflow"
            loop={true}
            autoplay={true}
            navigation={true}
            slidesPerView={4}
            spaceBetween={20}
            pagination={{ clickable: true }}
            speed={1200}
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
          >
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-13-25-004_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-13-31-986_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-13-45-243_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-14-06-011_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-21-45-299_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-57-05-631_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-53-10-057_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-53-25-900_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-57-10-442_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-16-02-23-879_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-15-01-27-639_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="slider_screen">
              <img
                src={require("../../Assets/newscreens/Screenshot_2023-12-24-15-02-15-316_com-portrait.png")}
                alt=""
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Screens;
