import React from 'react'
import './explanation.css'
const Explanation = ({question}) => {
  console.log(question)
  return (
    <>
    {
      question.map((item,index)=>{
        return (
          <div className={item.status?"explanation true":"explanation"}>
            <h4>Explanation: {item.ans_num}</h4>
            <p>{item.explanation}</p>
          </div>
        )
      })
    }
  </>
  )
}

export default Explanation
