export const courses=[
  {
    id:1,
    name:'General Surgery ',
    un_num:21,
    cat:'Surgery',
    image:require("../../../Assets/images/img1.png"),
    card_num:33,
  },
  {
    id:2,
    name:'General Surgery ',
    image:require("../../../Assets/images/img2.png"),
    cat:'Surgery',
    un_num:21,
    card_num:33,
  },
  // {
  //   id:3,
  //   name:'General Surgery ',
  //   image:require("../../assets/ing3.png"),
  //   cat:'Surgery',
  //   un_num:21,
  //   card_num:33,
  // },
];

export const unitsData=[
  {
    id:1,
    name:'Breast',
    card_num:'98'
  },
  {
    id:2,
    name:'Thyroid',
    card_num:'22'
  },
  {
    id:3,
    name:'Other Endocrine ',
    card_num:'33'
  },
]
