import React from 'react'
import './question.css'
const Question = ({questionData,handleUpdateSelect}) => {
  return (
    <div className='question'>
      <h4>{questionData.question_name}</h4>
      <div className="answers">
        {
          questionData?.res_answers?.map((item,index)=>{
            return (
              <p key={index} onClick={()=>{
                handleUpdateSelect(item,index);
              }} className='answer'>
                <span className={
                  (item.showans&&item.status==true)?
                  'showtrue'
                  :
                  (item.showans&&item.status==false)?
                  'showfalse'
                  :
                  ''
                  }
                  >
                    {item.ans_name}
                  </span>
                <p>
                  <span className={item.selected?'active':''}></span>
                </p>
              </p>
            )
          })
        }
      </div>
    </div>
  )
}

export default Question
