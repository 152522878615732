import React, { useEffect, useState } from 'react';
import './flashquestions.css';
import Banner from './Banner/Banner';
import { questionsData } from './data';
const FlashQuestions = ({ setPageName }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState([]);
  const getQuestions = () => {
    setQuestions(questionsData);
  };
  useEffect(() => {
    getQuestions();
  }, []);
  return (
    <div  className='flashquestions'>
      <Banner setPageName={setPageName}/>
        <div className="question_answer_component">
          <div className='flash_rate'>
            <div className="num">
              {currentQuestion+1}/{questions.length}
            </div>
            <div className='flashrate'>
              <h4 style={{backgroundColor:'#ef466a',height:'100%',borderRadius:'10px',width:`${((currentQuestion*1+1)/questions.length)*100}%`}}></h4>
            </div>
          </div>
          <div class={"card-flid"}>
            <div class="card-inner">
              <div class="card-front">
                {questions[currentQuestion]?.name}
              </div>

              <div class="card-back">
                {questions[currentQuestion]?.ans}
              </div>
            </div>
          </div>
          <div className="flashcardsbtns btns">
            {!(currentQuestion > 0) ? null : (
              <button
                className="btn"
                style={{ margin: "10px" }}
                onClick={() => {
                  if (currentQuestion > 0) {
                    setCurrentQuestion(currentQuestion-1);
                  }
                }}
              >
                Previos
              </button>
            )}
            {currentQuestion < questions?.length - 1 ? (
              <button
                className="btn btn-success"
                style={{ margin: "10px",color:'white' }}
                onClick={() => {
                  if (currentQuestion < questions?.length - 1) {
                    setCurrentQuestion(currentQuestion+1);
                  }
                }}
              >
                Next
              </button>
            ) : null}
          </div>
        </div>
    </div>
  );
};

export default FlashQuestions;
