export const questionsData=[
  {
    question_id:1,
    question_name:'acute osteomyelitis is most commonly caused by:',
    res_answers:[
      {
        ans_id:1,
        ans_name:'streotoccus pyogens',
        explanation:'not true',
        status:false,
        ans_num:'A',
      },
      {
        ans_id:2,
        ans_name:'H.infurnza',
        explanation:'not true',
        status:false,
        ans_num:'B',
      },
      {
        ans_id:3,
        ans_name:'staphylococus aureus',
        explanation:'it just True',
        status:true,
        ans_num:'C',
      },
      {
        ans_id:4,
        ans_name:'E-coli',
        status:false,
        explanation:'not true',
        ans_num:'D',
      },
    ]
  },
  {
    question_id:2,
    question_name:'New Deposite bone in acute hematogenous osteomyelitis is called',
    res_answers:[
      {
        ans_id:1,
        ans_name:'Brodie`s abscess',
        explanation:'not true',
        status:false,
        ans_num:'A',
      },
      {
        ans_id:2,
        ans_name:'Cloaca',
        explanation:'not true',
        status:false,
        ans_num:'B',
      },
      {
        ans_id:3,
        ans_name:'sequestrum',
        explanation:'it just True',
        status:false,
        ans_num:'C',
      },
      {
        ans_id:4,
        ans_name:'involucrum',
        status:true,
        explanation:'true',
        ans_num:'D',
      },
    ]
  },
]
