import React, { useEffect, useRef, useState } from "react";
import { Slide } from "react-awesome-reveal";

import Casses from "./Casses/Casses";
import TopBar from "../../../components/topbar";
import BottomBar from "../../../components/bottomBar";

const MobileCases = () => {
  const videoRef = useRef();

  const [isPlayed, setIsPlayed] = useState(true);
  const [toTry, setToTry] = useState(false);
  const [finished, setFinished] = useState(false);

  const [validAnswers, setValidAnswers] = useState([]);
  useEffect(() => {
    if (videoRef.current)
      videoRef.current.addEventListener("ended", () => {
        setIsPlayed(false);
      });
  }, [videoRef.current]);
  useEffect(() => {
    if (finished) {
      setFinished(false);
    }
  }, [finished]);

  return (
    // <div>

    <div direction="right" className="slideFeatures">
      <div class="device">
        <TopBar />
        {toTry ? (
          <Casses />
        ) : (
          <div className="video" style={{ marginTop: "0", height: "87%" }}>
            <video
              ref={videoRef}
              src={require("../../../Assets/videos/Cases.mp4")}
              muted
              autoPlay
              controls
            ></video>
            {isPlayed ? null : (
              <div className="video_overLay">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setIsPlayed(true);
                    videoRef.current.play();
                  }}
                >
                  Replay
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setToTry(true);
                  }}
                >
                  Try
                </button>
              </div>
            )}
          </div>
        )}
        <BottomBar />
      </div>
    </div>
    // </div>
  );
};

export default MobileCases;
