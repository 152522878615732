export const flashsData = [
  {
    id: 1,
    name: 'General Surgery',
    units_count: 21,
    cat: 'surgery',
    cards_count: 500,
    img: require("../../../../../Assets/images/img1.png"),
  },
  {
    id: 1,
    name: 'General Surgery',
    units_count: 6,
    cat: 'surgery',
    cards_count: 300,
    img: require("../../../../../Assets/images/img2.png"),
  },
  {
    id: 1,
    name: 'GIT Surgery',
    units_count: 16,
    cat: 'surgery',
    cards_count: 450,
    img: require("../../../../../Assets/images/ing3.png"),
  },
];
