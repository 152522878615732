import React from 'react';
import './topic.css';
const Topic = ({ topicData, setSelectedTopic, setPageName }) => {
  return (
    <div className='topic_compo'
    onClick={()=>{
      setPageName('questions');
    }}
  >
    <div className="left">
      <p>{topicData.id>10?topicData.id:'0'+topicData.id}</p>
      <div className="details">
        <h5>{topicData.name}</h5>
        <p>{topicData.top_nums} topic</p>
      </div>
    </div>
    <div className="right">
    <img src={require("../../../../../Assets/images/inter.png")} alt="" />
    </div>
  </div>
  );
};

export default Topic;
