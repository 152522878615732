// Tweets.js

import React, { useEffect, useRef, useState } from 'react';
import "./style.css";
import { Slide } from 'react-awesome-reveal';
import MobileTweets from './mobile';
import MobileWRQS from './mobile';
import { chartbar, clock, pencilIcon } from '../../../Assets/svgIcons';
import { androidLink } from '../../../data/applicationLinks';

const QuestionsWritten = ({ setComponent }) => {
  const componentRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.4,
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setComponent(<MobileTweets />);
      }
    });
  };

  return (
    <div className="features" ref={componentRef}>
      <div className="banner_content">
        <Slide direction="left">
          <div className="left_banner">
            <section className="mcq-features-section">
              <div className="section-header">
                <h2>Enhance Learning with Written Questions</h2>
              </div>
              <div className="illustration">
                <img
                  src={require("../../../Assets/images/writing.png")}
                  alt="Illustration"
                />
              </div>
              <div className="feature-icons">
                {pencilIcon}{" "}
                {/* Assuming you have an icon for a pencil or writing */}
                {clock} {/* Clock icon for time management */}
                {chartbar}
              </div>
              <div className="text-description">
                <p>
                  Elevate your learning experience with written questions. Dive
                  deep into comprehensive assessments, express your
                  understanding, and receive constructive feedback.
                </p>
              </div>
              <div className="sample-question">
                <p>
                  Explore thought-provoking written questions that cover a
                  diverse range of subjects. Gain valuable insights and refine
                  your knowledge through in-depth responses.
                </p>
              </div>
              <div className="cta-button">
                <a href={androidLink} target="_blanck" className="cta-btn">
                  Start Answering Now
                </a>
              </div>
            </section>
          </div>
        </Slide>
        <div className="visibility">
          <MobileWRQS />
        </div>
      </div>
    </div>
  );
};

export default QuestionsWritten;
