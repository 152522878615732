import React, { useState } from 'react';
import "./style.css";
import { PlayVideo } from '../../Assets/svgIcons';
import { Fade } from 'react-awesome-reveal';
const Preview = ({ ref }) => {
  const [showPreviewVideo, setShowPreviewVideo] = useState(false);
  if (showPreviewVideo) document.body.style.overflow = "hidden";
  else document.body.style.overflow = "initial";
  return (
    <Fade>
      <div className="preview" id="preview" ref={ref}>
        <div className="video-tour-item">
          <div className="icon" onClick={() => setShowPreviewVideo(true)}>
            {PlayVideo}
          </div>
          <div className="text cont">
            <h5
              style={{
                color: "var(--main-color)",
                fontSize: "17.3px",
                margin: "10px 0",
              }}
            >
              Watch Video Now
            </h5>
            <h6>02:32 minutes</h6>
          </div>
        </div>
      </div>
      {showPreviewVideo ? (
        <div direction="bottom" className="preview_video">
          <div
            className="overlay"
            onClick={() => setShowPreviewVideo(false)}
          ></div>
          <video
            src={require("../../Assets/33202130.mp4")}
            controls
            loop
          ></video>
        </div>
      ) : null}
    </Fade>
  );
};

export default Preview;
