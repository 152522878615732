import React, { useEffect, useState } from 'react';
// import './topicquestions.css'
// import Banner from './Banner/Banner'
import Question from './Question/Question';
import { questionsData } from './data';
import Banner from './Banner/Banner';
const CassesQuestions = ({ setPageName }) => {
  const [questions, setQuestions] = useState([]);
  const [curentQuestion, setCurentQuestion] = useState(0);
  const getQuestions = () => {
    let allData = [...questionsData];
    setQuestions(
      allData.map((item, index) => {
        if (item.id == 1) {
          return { ...item, show: true };
        } else return { ...item, show: false };
      })
    );
  };
  useEffect(() => {
    getQuestions();
  }, []);
  return (
    <div className="topicsquestions">
      <Banner setPageName={setPageName} />
      <div  className="questions">
        <Question
          setPageName={setPageName}
          questionData={questions[curentQuestion]}
        />
      </div>
      <div className="flashcardsbtns btns">
        {!(curentQuestion > 0) ? null : (
          <button
            className="btn"
            style={{ margin: "10px" }}
            onClick={() => {
              if (curentQuestion > 0) {
                setCurentQuestion(curentQuestion - 1);
              }
            }}
          >
            Previos
          </button>
        )}
        {curentQuestion < questions?.length - 1 ? (
          <button
            className="btn "
            style={{ margin: "10px" }}
            onClick={() => {
              if (curentQuestion < questions?.length - 1) {
                setCurentQuestion(curentQuestion + 1);
              }
            }}
          >
            Next
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default CassesQuestions;
