import React from 'react';
// import './cassesunit.css'
import './units.css';
import { FaArrowLeftLong } from "react-icons/fa6";
import { unitsData } from '../../../Flash-Cards/SecondPage/FlashUnits/data';
import Unit from './Unit/Unit';
const Units = ({ setPageName, setSelectedUnit }) => {
  return (
    <div className="tweetsunits">
      <h3>
        <div>
          <img src={require("../../../../../Assets/images/inter.png")} alt="" />
          <span>Written QS</span>
        </div>
        <FaArrowLeftLong
          onClick={() => {
            setPageName("courses");
          }}
        />
      </h3>
      <p>Written QS</p>
      <div className="units">
        {unitsData.map((item, index) => {
          return (
            <Unit
              setSelectedUnit={setSelectedUnit}
              setPageName={setPageName}
              unitData={item}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Units;
