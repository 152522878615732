import React, { useEffect, useRef, useState } from "react";
import { Slide } from "react-awesome-reveal";
import Modal from "../../../components/modal";
import SecondPage from "./SecondPage/SecondPage";
import TopBar from "../../../components/topbar";
import BottomBar from "../../../components/bottomBar";

const MobileFlashCards = () => {
  const videoRef = useRef();
  const questions = [
    {
      id: "1",
      face: "how are you ?",
      back: "I am fine Thank you ..!",
    },
    {
      id: "2",
      face: "how are you ?",
      back: "I am fine Thank you ..!",
    },
    {
      id: "3",
      face: "how are you ?",
      back: "I am fine Thank you ..!",
    },
    {
      id: "4",
      face: "how are you ?",
      back: "I am fine Thank you ..!",
    },
  ];
  const [isPlayed, setIsPlayed] = useState(true);
  const [toTry, setToTry] = useState(false);

  let [currentQuestion, setCurrentQuestion] = useState(0);

  useEffect(() => {
    if (videoRef.current)
      videoRef.current.addEventListener("ended", () => {
        setIsPlayed(false);
      });
  }, [videoRef.current]);

  return (
    <div direction="right" className="slideFeatures">
      <div class="device">
        <TopBar />
        {toTry ? (
          <>
            <SecondPage />
          </>
        ) : (
          <div className="video" style={{ marginTop: "0", height: "87%" }}>
            <video
              ref={videoRef}
              src={require("../../../Assets/videos/FlashCards.mp4")}
              muted
              autoPlay
              controls
            ></video>
            {isPlayed ? null : (
              <div className="video_overLay">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setIsPlayed(true);
                    videoRef.current.play();
                  }}
                >
                  Replay
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setToTry(true);
                  }}
                >
                  Try
                </button>
              </div>
            )}
          </div>
        )}
        <BottomBar />
      </div>
    </div>
  );
};

export default MobileFlashCards;
