import React from 'react';
import './tweetscourses.css';
import { coursesData } from './data';
import Course from './Course/Course';
const TweetsCourses = ({ setPageName }) => {
  return (
    <div className="tweetscourses">
      <h3>
        <img src={require("../../../../../Assets/images/twitty.png")} alt="" />
        <h4>Tweets</h4>
      </h3>
      <p>Select The Course For The Tweets</p>
      <div className="coursestweets">
        {coursesData.map((item, index) => {
          return (
            <Course key={index} courseData={item} setPageName={setPageName} />
          );
        })}
      </div>
    </div>
  );
};

export default TweetsCourses;
