import React, { useEffect, useState } from 'react';
// import './courses.css'
import Courses from './Courses/Courses';
import Units from './Units/Units';
import AllTopics from './AllTopics/AllTopics';
import { Questions } from './Questions/Questions';
const MainPage = () => {
  const [pageName, setPageName] = useState('courses');
  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedUnit, setSelectedUnit] = useState({});
  const [selectedTopic, setSelectedTopic] = useState({});
  return pageName == 'courses' ? (
    <Courses setSelectedCourse={setSelectedCourse} setPageName={setPageName} />
  ) : pageName == 'unites' ? (
    <Units
      setSelectedUnit={setSelectedUnit}
      setPageName={setPageName}
      selectedCourse={selectedCourse}
    />
  ) : pageName == 'alltopics' ? (
    <AllTopics
      selectedUnit={selectedUnit}
      setPageName={setPageName}
      setSelectedTopic={setSelectedTopic}
    />
  ) : pageName == 'questions' ? (
    <Questions
      selectedCourse={selectedCourse}
      selectedTopic={selectedTopic}
      setPageName={setPageName}
    />
  ) : null;
};

export default MainPage;
