// Features.js

import React, { useEffect, useState } from 'react';
import "./style.css";
import FlashCards from './Flash-Cards';
import MCQS from './MCQS';
import Tweets from './Tweets';
import MobileMCQS from './MCQS/mobile';
import QuestionsWritten from './WrittenQuestion';
import Cases from './Cases';
import CasesTwo from './Cases';

const Features = ({ visible }) => {
  const [component, setComponent] = useState(<MobileMCQS />);

  return (
    <div className="features" id="features">
      {visible ? <div className="fixedDiv">{component}</div> : null}
      <div className="breadCrumb">
        <h1 className="crumb">App Features</h1>
        <p>MCQS - Flash Cards - Tweets - Written Questions - Cases</p>
      </div>
      <MCQS setComponent={setComponent} />
      <FlashCards setComponent={setComponent} />
      <Tweets setComponent={setComponent} />
      <QuestionsWritten setComponent={setComponent} />
      <CasesTwo setComponent={setComponent} />
    </div>
  );
};

export default Features;
