export const unitsData=[
  {
    unit_id:1,
    unit_name:'Surgical Infections',
    question_num:52,
  },
  {
    unit_id:2,
    unit_name:'Trauma',
    question_num:77,
  },
  {
    unit_id:3,
    unit_name:'Surgical Oncology',
    question_num:22,
  },
  {
    unit_id:4,
    unit_name:'Surgical Nutrition',
    question_num:33,
  },
]
