// Features.js

import React, { useEffect, useState, useRef } from 'react';
import "./style.css";
import FlashCards from './Flash-Cards';
import MCQS from './MCQS';
import Tweets from './Tweets';
import FixedDiv from './fixedLayout';
import QuestionsWritten from './WrittenQuestion';
import CasesTwo from './Cases';

const Features = ({ ref }) => {
  const componentRef = useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
  };

  return (
    <div className="features" id="featurs" ref={componentRef}>
      <div className="noneDisplay" style={{ display: "none" }}>
        <MCQS />
        <FlashCards />
        <Tweets />
        <QuestionsWritten />
        <CasesTwo />
      </div>
      <FixedDiv visible={visible} />
    </div>
  );
};

export default Features;
