import React, { useState } from 'react';
import InterActivePage from './interactivepage/InterActivePage';
import FlashCards from './FlashCards/FlashCards';
import FlashUnits from './FlashUnits/FlashUnits';
import './secondpage.css';
import FlashTopics from './FlashTopics/FlashTopics';
import FlashQuestions from './FlashQuestions/FlashQuestions';
const SecondPage = () => {
  const [pageName, setPageName] = useState('flashcards');
  console.log(pageName,"eddw")
  console.log("erre")
  const [selectedUnit, setSelectedUnit] = useState({});
  return (
    <div className="secondpage">
      {pageName !== 'flastopics' && pageName !== 'flashquestions' ? (
        pageName!=='questions'?(
          <>
            <h3 style={{ padding: '15px' }}>
          <img src={require("../../../../Assets/images/flash.png")} alt="" />
          <p>Flash Cards</p>
        </h3>
          </>
        ):(null)
      ) : null}
      {pageName == 'flashcards' ? (
        <FlashCards setPageName={setPageName} />
      ) : pageName == 'flashunits' ? (
        <FlashUnits
          setSelectedUnit={setSelectedUnit}
          setPageName={setPageName}
        />
      ) : pageName == 'flastopics' ? (
        <FlashTopics selectedUnit={selectedUnit} setPageName={setPageName} />
      ) : pageName == 'flashquestions' ? (
        <FlashQuestions setPageName={setPageName} />
      ) : <FlashQuestions setPageName={setPageName} />}
    </div>
  );
};

export default SecondPage;
