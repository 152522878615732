import React, { useEffect, useState } from "react";
import Course from "./Course/Course";
import "./courses.css";
import Skeleton from "react-loading-skeleton";
import { courses } from "./data";
const Courses = ({ setPageName, setSelectedCourse }) => {
  const [coursesData, SetCoursesData] = useState([]);
  // const [pageName,setPageName]=useState('courses');
  const [pageLoading, setPageLoading] = useState(false);
  const [selType, setSelType] = useState("training");
  const [coursesType, setCourseType] = useState("mock");
  const getCourses = () => {
    SetCoursesData(courses);
  };
  useEffect(() => {
    getCourses();
  }, []);
  return (
    <>
      <div
        style={{
          padding: "10px",
          marginBottom: "0px",
          marginTop: "10px",
          paddingBottom: "0px",
        }}
        className="header"
      >
        <div className="left">
          <img src={require("../../../Assets/images/flash.png")} alt="" />
          <h4 style={{ fontSize: "16px" }}>MCQ Question</h4>
        </div>
        <div className="right"></div>
      </div>
      {/* <div  className='courses_typs'>
            <div className={coursesType=='mock'?'course_type active':'course_type'}
              onClick={()=>{
                setCourseType('mock');
              }}
            >Mock Exams</div>
            <div className={coursesType=='trq'?'course_type active':'course_type'}
              onClick={()=>{
                setCourseType('trq');
              }}
            >Training Qs</div>
          </div> */}
      <p style={{ padding: "10px", paddingTop: "0px" }} className="text">
        Select the Course for the questions
      </p>
      <div
        style={{ padding: "10px", paddingTop: "0px", marginTop: "0px" }}
        className="content"
      >
        {courses.map((item, index) => {
          return (
            <Course
              setSelectedCourse={setSelectedCourse}
              courseData={item}
              key={index}
              setPageName={setPageName}
            />
          );
        })}
      </div>
    </>
  );
};

export default Courses;
