import React, { useEffect, useState } from 'react';
import './questions.css';
import Banner from './Banner/Banner';
import { questionsData } from './data';
import Question from './Question/Question';
import Explanation from './Explanation/Explanation';
export const Questions = ({ selectedTopic, setPageName, selectedCourse }) => {
  const [questions, setQuestions] = useState([]);
  const [showExplanation, setShowExplanation] = useState(false);
  const [quesSelected, setQuesSelected] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [questionNowNum, setQuestionNowNum] = useState(1);
  const getQuestions = () => {
    let allData = [...questionsData];
    setQuestions(
      allData.map((item) => {
        return { ...item, checked: item.question_id == 1 ? 'true' : 'false' };
      })
    );
    let pushedData = [];
    for (let i = 0; i < allData.length; i++) {
      let obj = {
        ...allData[i],
        role: i == 0 ? true : false,
      };
      let answers = allData[i]?.res_answers;
      for (let j = 0; j < answers.length; j++) {
        answers[j].selected = false;
      }
      obj['res_answers'] = answers;
      pushedData.push(obj);
    }
    setQuestions(pushedData);
  };
  const handleUpdateSelect = (item, index) => {
    setQuesSelected(true);
    let allData = [...questions];
    let pushedArr = [];
    for (let i = 0; i < allData.length; i++) {
      let obj = {
        ...allData[i],
      };
      let answers = allData[i].res_answers;
      for (let k = 0; k < answers.length; k++) {
        if (k == index) {
          answers[index].selected = !answers[index].selected;
        } else {
          answers[k].selected = false;
        }
      }
      obj['res_answers'] = answers;
      pushedArr.push(obj);
    }
    setQuestions(pushedArr);
  };
  const handleShowtrue = () => {
    setShowExplanation(true);
    setShowNext(true);
    let allData = [...questions];
    let pushedArr = [];
    for (let i = 0; i < allData.length; i++) {
      let obj = {
        ...allData[i],
        // showans:true
      };
      let answers = allData[i]?.res_answers;
      for (let j = 0; j < answers.length; j++) {
        answers[j].showans = true;
      }
      obj['res_answers'] = answers;
      pushedArr.push(obj);
    }
    setQuestions(pushedArr);
    console.log(pushedArr);
  };
  const handleChangeQues = () => {
    let allData = [...questions];
    let index = 0;
    for (let i = 0; i < allData.length; i++) {
      let pushedArr = [];
      for (let i = 0; i < allData.length; i++) {
        if (allData[i].role == true) {
          index = i;
        }
        let obj = {
          ...allData[i],
          // showans:true
        };
        let answers = allData[i]?.res_answers;
        for (let j = 0; j < answers.length; j++) {
          answers[j].showans = false;
        }
        obj['res_answers'] = answers;
        pushedArr.push(obj);
      }
      setQuestions(pushedArr);
      console.log(pushedArr);
    }
    // allData[index+1].role=true
    // allData[index].role=false
    // setQuestions(allData)
    // console.log(allData)
    setShowExplanation(false);
    handleChangeQuestion(index);
    setShowNext(true);
    // setShowNext
  };
  const handleChangeQuestion = (index) => {
    let allData = [...questions];
    if (index == allData.length - 1) {
      setShowNext(false);
    } else {
      let pushedData = [];
      for (let i = 0; i < allData.length; i++) {
        let obj = {
          ...allData[i],
          role: i == index + 1 ? true : false,
        };
        let answers = allData[i]?.res_answers;
        for (let j = 0; j < answers.length; j++) {
          answers[j].selected = false;
        }
        obj['res_answers'] = answers;
        pushedData.push(obj);
      }
      setQuestions(pushedData);
      setQuestionNowNum(questionNowNum + 1);
      // allData[index].role=false;
      // allData[index+1].role=true;
    }
  };
  useEffect(() => {
    getQuestions();
  }, []);
  return (
    <div className="questions_page">
      <Banner
        setPageName={setPageName}
        selectedTopic={selectedTopic}
        selectedCourse={selectedCourse}
      />
      <div className="question_answered">
        <div className="left">{questionNowNum}/2</div>
        <div className="right">
          <div></div>
          <img src={require("../../../Assets/images/idea.png")} alt="" />
        </div>
      </div>
      {questions
        .filter((it) => it.role == true)
        .map((item, index) => {
          return (
            <Question
              handleUpdateSelect={handleUpdateSelect}
              questionData={item}
              key={index}
            />
          );
        })}
      {quesSelected ? (
        <div className="sub_div">
          <div className="btn">
            <button
              onClick={() => {
                handleShowtrue();
              }}
            >
              Submit Answers
            </button>
          </div>
          {showExplanation ? (
            <div className="explanations">
              {questions
                .filter((it) => it.role)
                .map((item, index) => {
                  return <Explanation question={item.res_answers} />;
                })}
            </div>
          ) : null}
        </div>
      ) : null}
      {showNext ? (
        <div className="actions">
          <button
            onClick={() => {
              handleChangeQues();
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              handleChangeQues();
            }}
          >
            Next
          </button>
        </div>
      ) : null}
    </div>
  );
};
