import React from 'react';
import './flashcards.css';
import { flashsData } from './data';
import FlashCard from './FlashCard/FlashCard';
const FlashCards = ({ setPageName }) => {
  return (
    <div
      // style={{ padding: '15px' }}
      onClick={() => {
        setPageName('flashunits');
      }}
      className="courses_comp"
    >
      {/* <h3>
        <img src={require("../../../assets/flash.png")} alt="" />
        <p>Flash Cards</p>
      </h3> */}
      <p style={{paddingLeft:'10px'}}>Select the Course for the flashcards </p>
      <div className="courses">
        {flashsData.map((item, index) => {
          return (
            <FlashCard flashData={item} key={index} setPageName={setPageName} />
          );
        })}
      </div>
    </div>
  );
};

export default FlashCards;
