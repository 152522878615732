export const NavBarData = [
  {
    label_en: "Home",
    label_ar: "الرئيسية",
    link: "#",
  },
  {
    label_en: "Features",
    label_ar: "المميزات",
    link: "#featurs",
  },
  {
    label_en: "Why Us",
    label_ar: "لماذا نحن",
    link: "#whyus",
  },
  {
    label_en: "Preview",
    label_ar: "صور الأبليكيشن",
    link: "#preview",
  },
  // {
  //   label_en: "Reviews",
  //   label_ar: "آراء العملاء",
  //   link: "#testimonials",
  // },
];
