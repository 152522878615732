import React, { useEffect, useRef, useState } from 'react';
import "./style.css";
import { Fade, Slide } from 'react-awesome-reveal';
import { chartbar, checkCircle, clock } from '../../../Assets/svgIcons';
import Modal from '../../../components/modal';
import { InView, useInView } from 'react-intersection-observer';
import MobileMCQS from './mobile';
import { androidLink } from '../../../data/applicationLinks';

const MCQS = ({ setComponent }) => {
  const videoRef = useRef();
  const componentRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.4,
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setComponent(<MobileMCQS />);
      }
    });
  };
  const questions = [
    {
      id: "1",
      title: "how are you ?",
      answers: [
        { id: "1", title: "old", valid: true },
        { id: "2", title: "new", valid: false },
      ],
    },
    {
      id: "2",
      title: "how are you ?",
      answers: [
        { id: "1", title: "old2", valid: false },
        { id: "2", title: "new2", valid: true },
      ],
    },
    {
      id: "3",
      title: "how are you ?",
      answers: [
        { id: "1", title: "old3", valid: true },
        { id: "2", title: "new3", valid: false },
      ],
    },
    {
      id: "4",
      title: "how are you ?",
      answers: [
        { id: "1", title: "old4", valid: true },
        { id: "2", title: "new4", valid: false },
      ],
    },
  ];
  const [isPlayed, setIsPlayed] = useState(true);
  const [toTry, setToTry] = useState(false);
  const [finishCheck, setFinishCheck] = useState(false);
  const [finished, setFinished] = useState(false);
  const [checkedLi, setCheckedLi] = useState(null);
  let [currentQuestion, setCurrentQuestion] = useState(0);
  const [validAnswer, setValidAnswer] = useState(null);
  const [validAnswers, setValidAnswers] = useState([]);
  useEffect(() => {
    if (videoRef.current)
      videoRef.current.addEventListener('ended', () => {
        setIsPlayed(false);
      });
  }, [videoRef.current]);
  useEffect(() => {
    if (finished) {
      console.log(validAnswers);
      setFinished(false);
    }
  }, [finished]);

  return (
    <InView
      as="div"
      // onChange={(inView, entry, ref) => setComponent(<MobileMCQS />)}
    >
      <div className="features" id="mcqs" ref={componentRef}>
        <div className="banner_content">
          <Slide direction="left">
            <div className="left_banner">
              <section class="mcq-features-section">
                <div class="section-header">
                  <h2>Interactive Learning with MCQs</h2>
                </div>
                <div class="illustration">
                  <img
                    src={require("../../../Assets/images/8305578.png")}
                    alt="Illustration"
                  />
                </div>
                <div class="feature-icons">
                  {checkCircle}
                  {clock}
                  {chartbar}
                </div>
                <div class="text-description">
                  <p>
                    Experience the power of Multiple Choice Questions (MCQs) for
                    effective self-assessment, instant feedback, and interactive
                    learning.
                  </p>
                </div>
                <div class="sample-question">
                  <p>
                    Test your knowledge with challenging quizzes and assessments
                    covering a wide range of topics. Gain instant insights into
                    your understanding of each subject.
                  </p>
                </div>

                <div class="cta-button">
                  <a href={androidLink} target="_blanck" class="cta-btn">
                    Start Learning Now
                  </a>
                </div>
              </section>
            </div>
          </Slide>
          <div className="visibility">
            <MobileMCQS />
          </div>
        </div>
      </div>
    </InView>
  );
};

export default MCQS;
