import React from 'react';
import './tweetsunits.css';
import { FaArrowLeftLong } from "react-icons/fa6";
import { unitsData } from '../../../Flash-Cards/SecondPage/FlashUnits/data';
import Unit from './Unit/Unit';
const TweetsUnits = ({ setPageName, setSelectedUnit }) => {
  return (
    <div className="tweetsunits">
      <h3>
        <div>
          {/* <img
            src={require("../../../../../Assets/images/twitty.png")}
            alt=""
          /> */}
          <span>Tweets</span>
        </div>
        <FaArrowLeftLong
          onClick={() => {
            setPageName("courses");
          }}
        />
      </h3>
      <p>General Surgery</p>
      <div className="units">
        {unitsData.map((item, index) => {
          return (
            <Unit
              setSelectedUnit={setSelectedUnit}
              setPageName={setPageName}
              unitData={item}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TweetsUnits;
