import React from 'react';
import './course.css';
const Course = ({ courseData, setPageName }) => {
  return (
    <div
      onClick={() => {
        setPageName('tweetsunits');
      }}
      className="course_page"
    >
        <div className="left">
        <img src={courseData.img} alt="" />
      </div>
      <div className="right">
        <h5>{courseData.cat}</h5>
        <h4>{courseData.name}</h4>
        <p>{courseData.unit_num}units,{courseData.card_num}cards</p>
      </div>
    </div>
  );
};

export default Course;
