import React from 'react';
import './unit.css';
const Unit = ({ setPageName, setSelectedUnit, unitData }) => {
  return (
    <div
      onClick={() => {
        setPageName('alltopics');
        setSelectedUnit(unitData);
      }}
      className="unit_com"
    >
      <div className="left">
        <h5>
          {unitData.unit_id * 1 < 10
            ? '0' + unitData.unit_id
            : unitData.unit_id}
        </h5>
        <div>
          <h3>{unitData.unit_name}</h3>
          <p>{unitData.question_num} questions</p>
        </div>
      </div>
      <div className="right">
        <img src={require("../../../../Assets/images/questions.png")} alt="" />
      </div>
    </div>
  );
};

export default Unit;
