// Tweets.js

import React, { useEffect, useRef, useState } from 'react';
import "./style.css";
import { Slide } from 'react-awesome-reveal';
import MobileTweets from './mobile';

const Tweets = ({ setComponent }) => {
  const componentRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.4,
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setComponent(<MobileTweets />);
      }
    });
  };

  return (
    <div className="features" ref={componentRef}>
      <div className="banner_content">
        <Slide direction="left">
          <div className="left_banner">
            <section className="tweet-section">
              <div className="user-profile-img">
                <img
                  src={require("../../../Assets/images/CLIPLY_372109260_TWITTER_LOGO_400.gif")}
                  alt="Profile Image"
                />
              </div>
              <div className="tweet-content">
                <p>
                  Enhance your learning journey with our tweet-like feature.
                  Share insights, discuss topics, and connect with fellow
                  learners effortlessly.
                </p>
              </div>
            </section>
          </div>
        </Slide>
        <div className="visibility">
          <MobileTweets />
        </div>
      </div>
    </div>
  );
};

export default Tweets;
