import React from 'react'
import './flashunits.css'
import { FaArrowLeftLong } from "react-icons/fa6";
import { unitsData } from './data'
import FlashUnit from './FlashUnit/FlashUnit'
const FlashUnits = ({setPageName,setSelectedUnit}) => {
  return (
    <div className='flashunits_page'>
      <p>
        <FaArrowLeftLong
          onClick={()=>{
            setPageName('flashcards')
          }}
        />
        <span>Flash Cards</span>
      </p>
      {
        unitsData.map((item,index)=>{
          return(
            <FlashUnit setSelectedUnit={setSelectedUnit} key={index} flashData={item} setPageName={setPageName}/>
          )
        })
      }
    </div>
  )
}

export default FlashUnits
