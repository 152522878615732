import React, { useState } from 'react';
import "./style.css";
import { PlayVideo } from '../../Assets/svgIcons';
import { Fade, Slide } from 'react-awesome-reveal';
import { androidLink, iosLink } from '../../data/applicationLinks';
const DownloadApp = ({ ref }) => {
  return (
    <>
      <div className="whyus" id="downloadApp" ref={ref}>
        <p>Navigate your medical career with confidence.</p>
        <h1>First Application In Egypt</h1>
        <p>
          Empower your medical journey today – Take charge of your professional
          growth with our comprehensive medical courses application.
        </p>
        <div className="downloadFooter">
          <img
            src={require("../../Assets/images/Available-on-the-App-Store-.png")}
            alt=""
            onClick={() => window.open(iosLink, "_blanck")}
          />
          <img
            src={require("../../Assets/images/en_badge_web_generic.png")}
            alt=""
            onClick={() => window.open(androidLink, "_blanck")}
          />
        </div>
      </div>
    </>
  );
};

export default DownloadApp;
