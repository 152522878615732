import Footer from "../footer";
import Header from "../header";

function DefaultLayout({ classes, children }) {
  return (
    <div
      className={
        classes && classes?.length
          ? "websiteContainer " + classes
          : "websiteContainer"
      }
    >
      <Header />
      <div className="childs">{children ? children : null}</div>
      <Footer />
    </div>
  );
}

export default DefaultLayout;
