export const  questionsData=[
  {
    qus_id:1,
    qus_name:'New Deposite bone in acute hematogenous osteomyelitis is called',
  },
  {
    qus_id:2,
    qus_name:'acute osteomyelitis is most commonly caused by:',
  },
  {
    qus_id:3,
    qus_name:'New Deposite bone in acute hematogenous osteomyelitis is called',
  },
  {
    qus_id:4,
    qus_name:'acute osteomyelitis is most commonly caused by:',
  },
]
