import React, { useState } from 'react';
import "./style.css";
import { PlayVideo } from '../../Assets/svgIcons';
import { Fade, Slide } from 'react-awesome-reveal';
const Whyus = ({ ref }) => {
  const LeftWhy = [
    {
      icon: require("../../Assets/icons/healthcare.png"),
      head: 'Quality',
      text: 'Access a range of medical courses taught by experts.',
    },
    {
      icon: require("../../Assets/icons/switch.png"),
      head: 'Engagement',
      text: 'Learn interactively to enhance your understanding.',
    },
    {
      icon: require("../../Assets/icons/work-schedule.png"),
      head: 'Flexibility',
      text: 'Learn at your pace with schedules to fit your life.',
    },
    {
      icon: require("../../Assets/icons/first-aid-kit.png"),
      head: 'Advancement',
      text: 'Stay updated with the latest in medical science.',
    },
    {
      icon: require("../../Assets/icons/application.png"),
      head: 'Application',
      text: 'Apply knowledge in practical scenarios for experience.',
    },
    {
      icon: require("../../Assets/icons/promotion.png"),
      head: 'Career Growth',
      text: 'Advance your medical career with courses designed for growth.',
    },
  ];

  return (
    <>
      <div className="breadCrumb">
        <h1 className="crumb">Why Us</h1>
        <h4>Why we are always recommended ?!</h4>
        <p>What can we offer you ?!</p>
      </div>
      <div className="whyus" id="whyus" ref={ref}>
        <div className="leftWhy">
          {LeftWhy?.map((item, index) => {
            if (index < 3)
              return (
                <Slide direction="left">
                  <div className="whyDesc" key={index}>
                    <div className="whyDescIcon">
                      <img src={item?.icon} alt="" />
                    </div>
                    <h2
                      className="whyDescHead"
                      style={{ color: "var(--main-color)" }}
                    >
                      {item?.head}
                    </h2>
                    <p
                      className="whyDescText"
                      style={{ color: "var(--secondary-color)" }}
                    >
                      {item?.text}
                    </p>
                  </div>
                </Slide>
              );
          })}
        </div>
        <Fade>
          <img
            src={require("../../Assets/newscreens/Screenshot_2023-12-24-15-02-15-316_com-portrait.png")}
            alt=""
          />
        </Fade>
        <div className="rightWhy">
          {LeftWhy?.map((item, index) => {
            if (index > 2)
              return (
                <Slide direction="right">
                  <div className="whyDesc" key={index}>
                    <div className="whyDescIcon">
                      <img src={item?.icon} alt="" />
                    </div>
                    <h2
                      className="whyDescHead"
                      style={{ color: "var(--main-color)" }}
                    >
                      {item?.head}
                    </h2>
                    <p
                      className="whyDescText"
                      style={{ color: "var(--secondary-color)" }}
                    >
                      {item?.text}
                    </p>
                  </div>
                </Slide>
              );
          })}
        </div>
      </div>
    </>
  );
};

export default Whyus;
