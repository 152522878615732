import React from 'react';
import './flashunit.css';
const FlashUnit = ({ flashData, setPageName, setSelectedUnit }) => {
  return (
    <div
      className="flashunit"
      onClick={() => {
        setPageName('flastopics');
        setSelectedUnit(flashData);
      }}
    >
      <div className="left">
        <p>
          {flashData.unit_id > 10 ? flashData.unit_id : '0' + flashData.unit_id}
        </p>
        <div className="det">
          <h4>{flashData.unit_name}</h4>
          <p>{flashData.flash_num} flash card</p>
        </div>
      </div>
      <div className="right">
        <img
          src={require("../../../../../../Assets/images/flash.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default FlashUnit;
