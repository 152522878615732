import React, { useState } from 'react'
import Courses from './Courses/Courses'
import CassesUnits from './CassesUnit/CassesUnits'
import CassesTopics from './CassesTopics/CassesTopics';
import CassesQuestions from './CassesQuestions/CassesQuestions';

const Casses = () => {
  const [selectedUnit,setSelectedUnit]=useState({});
  const [pageName,setPageName]=useState('cassescourses')
  return (
    <div className='casses_page'>
      {
        pageName=='cassescourses'?
        (
          <Courses setPageName={setPageName}/>
        )
        :
        pageName=='cassesunits'?
        (
          <CassesUnits setSelectedUnit={setSelectedUnit} setPageName={setPageName}/>
        )
        :
        pageName=='cassestopics'?
        (
          <CassesTopics unitData={selectedUnit} setPageName={setPageName}/>
        )
        :
        pageName=='cassesquestions'?
        (
          <CassesQuestions setPageName={setPageName}/>
        )
        :
        (null)
      }
    </div>
  )
}

export default Casses
