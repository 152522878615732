import React, { useEffect, useState } from 'react'
import './units.css'
import { FaArrowLeftLong } from "react-icons/fa6";
import axios from 'axios'
import Unit from './Unit/Unit';
import { unitsData } from './Unit/data';
const Units = ({setPageName,selectedCourse,setSelectedUnit}) => {
  const [units,setUnits]=useState([]);
  const [pageLoading,setPageLoading]=useState(true);
  const getCourseUnits=()=>{
    setUnits(unitsData)
  }
  useEffect(()=>{
    getCourseUnits()
  },[selectedCourse])
  return (
    <div className='units_page_unit' style={{padding:'10px'}}>
      <div className='header'>
        <div className="left">
          <FaArrowLeftLong
            onClick={()=>{
              setPageName("courses")
            }}
          />
          <h4>Questions</h4>
        </div>
        <div className="right">
        </div>
      </div>
      <p className='text' style={{marginTop:'-10px'}}>
        {selectedCourse.name}
      </p>
      <div className="contant">
        {
          unitsData.map((item,index)=>{
            return <Unit setSelectedUnit={setSelectedUnit}  unitData={item} key={index} setPageName={setPageName} />
          })
        }
      </div>
    </div>
  )
}

export default Units
