import React from 'react';
import './banner.css';
import { FaArrowLeft } from "react-icons/fa6";
const Banner = ({ selectedCourse, selectedTopic, setPageName }) => {
  return (
    <div className="banner_menu">
      <h3>
        <span>{selectedCourse.name}</span>
        <FaArrowLeft
          onClick={() => {
            setPageName("alltopics");
          }}
        />
      </h3>
      <h4>{selectedTopic.topic_name} All Topics</h4>
      <div className="re_keyword">
        <div className="left">
          <img src={require("../../../../Assets/images/problem.png")} alt="" />
          <p style={{ color:'white' }}>Report a Problem</p>
        </div>
        <div className="right">
          <img src={require("../../../../Assets/images/key.png")} alt="" />
          <p style={{ color:'white' }}>keywords</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
