import React, { useState } from 'react';
import TweetsCourses from './TweetsCourses/TweetsCourses';
import TweetsUnits from './TweetsUnits/TweetsUnits';
import TweetsTopics from './TweetsTopics/TweetsTopics';
import TopicQuestions from './TopicQuestions/TopicQuestions';

const TweetsQues = () => {
  const [pageName, setPageName] = useState('courses');
  const [selectedUnit, setSelectedUnit] = useState({});
  return (
    <div className="tweets">
      {pageName == 'courses' ? (
        <TweetsCourses setPageName={setPageName} />
      ) : pageName == 'tweetsunits' ? (
        <TweetsUnits
          setSelectedUnit={setSelectedUnit}
          setPageName={setPageName}
        />
      ) : pageName == 'tweetstopics' ? (
        <TweetsTopics unitData={selectedUnit} setPageName={setPageName} />
      ) : pageName == 'topicquestions' ? (
        <TopicQuestions setPageName={setPageName} />
      ) : null}
    </div>
  );
};

export default TweetsQues;
