import React from 'react';
import './question.css';
const Question = ({ setPageName, questionData }) => {
  // console.log(questionData)
  return (
    <div className="question">
      <img src={questionData && questionData.img} alt="" />
      {/* <h4>
        <img
          src={require("../../../../../../Assets/images/twitty.png")}
          alt=""
        />
      </h4> */}
      <p>{questionData && questionData?.qus_name}</p>
    </div>
  );
};

export default Question;
