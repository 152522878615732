import React from 'react';
import './course.css';
const Course = ({ courseData, setPageName }) => {
  return (
    <div
      onClick={() => {
        setPageName('units');
      }}
      className="coursedata"
    >
      <div className="left">
        <img src={require("../../../../../../Assets/images/img1.png")} alt="" />
      </div>
      <div className="right">
        <h5>{courseData.cat}</h5>
        <h4>{courseData.name}</h4>
        <p>
          <span>{courseData.unit_count} unit,</span>
          <span>{courseData.lessons} lessons</span>
        </p>
      </div>
    </div>
  );
};

export default Course;
