import React from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
// import './tweetstopics.css'
import './cassestopics.css';
import { IoIosRefreshCircle } from 'react-icons/io';
import { topicsData } from '../../../MainPage/AllTopics/data';
import Topic from './Topic/Topic';
const CassesTopics = ({ setPageName, unitData }) => {
  return (
    <div className="tweetstopics">
      <div>
        <FaArrowLeftLong
          onClick={() => {
            setPageName('cassesunits');
          }}
        />
        <h3>Casses Topics</h3>
        <div>
          <IoIosRefreshCircle />
          <h3>Refresh Topic</h3>
        </div>
      </div>
      <p>{unitData.unit_name}</p>
      {topicsData.map((item, index) => {
        return <Topic key={index} setPageName={setPageName} topicData={item} />;
      })}
    </div>
  );
};

export default CassesTopics;
