import React from 'react';
import { FaArrowLeft, FaArrowLeftLong } from "react-icons/fa6";
import { IoIosRefreshCircle } from 'react-icons/io';
import './flashopics.css';
import { topicsData } from './data';
import FlashTopic from './FlashTopic/FlashTopic';
import Topic from '../../../MainPage/AllTopics/Topic/Topic';
const FlashTopics = ({ setPageName, selectedUnit }) => {
  return (
    <div className='topics_project_page'>
      <div className="header">
        <FaArrowLeft
          onClick={()=>{
            setPageName('units');
          }}
        />
        <h4>Cards Topic</h4>
        <div>
          <IoIosRefreshCircle/>
          <h4>Refresh Topic</h4>
        </div>
      </div>
      <div className="text">
        <p>{selectedUnit.name}</p>
      </div>
      <div className="content">
        {
          topicsData.map((item,index)=>{
            return(
              <Topic setPageName={setPageName} key={index} topicData={item}/>
            )
          })
        }
      </div>
    </div>
  );
};

export default FlashTopics;
