import React from 'react';
import './unit.css';
const Unit = ({ setPageName, unitData, setSelectedUnit }) => {
  return (
    <div
      className="unit"
      onClick={() => {
        setPageName('tweetstopics');
        setSelectedUnit(unitData);
      }}
    >
      <div className="left">
        <p>
          {unitData.unit_id > 10 ? unitData.unit_id : '0' + unitData.unit_id}
        </p>
        <div className="det">
          <h4>{unitData.unit_name}</h4>
          <p>{unitData.flash_num}Tweets</p>
        </div>
      </div>
      <div className="right">
        <img
          src={require("../../../../../../Assets/images/twitty.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default Unit;
