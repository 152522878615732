import React from 'react';
import "./style.css";
import { androidLink, iosLink } from '../../data/applicationLinks';
import { Slide } from 'react-awesome-reveal';
const Home = ({ ref }) => {
  return (
    <div className="banner" ref={ref} id="home">
      <div className="banner_content">
        <Slide direction="left">
          <div className="left_banner">
            <div className="text">
              <h6>Security - Privacy - User friendly</h6>
              <h1>Embark on a Journey of Medical Excellence</h1>
              <p>
                Explore our comprehensive range of medical courses designed to
                empower and inspire. From foundational knowledge to advanced
                practices.
              </p>
            </div>
            <div className="left_banner_images">
              <div className="image">
                <img
                  src={require("../../Assets/images/en_badge_web_generic.png")}
                  alt=""
                  onClick={() => window.open(androidLink, "_blanck")}
                />
                <div className="rate">
                  <h3>5/5</h3>
                  <div className="rate_images">
                    {Array(5)
                      .fill(0)
                      .map((item, index) => {
                        return (
                          <img
                            src={require("../../Assets/images/favourite.png")}
                            alt={item}
                            key={index}
                          />
                        );
                      })}
                  </div>
                  <p>On the Adroid App Store</p>
                </div>
              </div>
              <div className="image">
                <img
                  src={require("../../Assets/images/Available-on-the-App-Store-.png")}
                  alt=""
                  onClick={() => window.open(iosLink, "_blanck")}
                />
                <div className="rate">
                  <h3>5/5</h3>
                  <div className="rate_images">
                    {" "}
                    {Array(5)
                      .fill(0)
                      .map((item, index) => {
                        return (
                          <img
                            src={require("../../Assets/images/favourite.png")}
                            alt={item}
                            key={index}
                          />
                        );
                      })}
                  </div>
                  <p>On the iOS App Store</p>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <Slide direction="right">
          <div className="right_banner">
            <img
              src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-13-31-986_com-portrait.png")}
              alt=""
            />
            <img
              src={require("../../Assets/newscreens/Screenshot_2023-12-24-14-13-25-004_com-portrait.png")}
              alt=""
            />
          </div>
        </Slide>{" "}
      </div>
    </div>
  );
};

export default Home;
