import React from 'react';
import './topic.css';
const Topic = ({ topicData, setPageName }) => {
  return (
    <div
      className="topic"
      onClick={() => {
        setPageName('topicquestions');
      }}
    >
      <div className="left">
        <h5>
          {topicData.id > 10 ? topicData.topic_id : '0' + topicData.topic_id}
        </h5>
        <div className="det2">
          <h3>{topicData.topic_name}</h3>
          <p>{topicData.question_num} Topics</p>
        </div>
      </div>
      <img src={require("../../../../../../Assets/images/twitty.png")} alt="" />
    </div>
  );
};

export default Topic;
