import React from 'react'
import './course.css'
const Course = ({courseData,setSelectedCourse,setPageName}) => {
  return (
    <div onClick={()=>{
      setPageName('unites')
      setSelectedCourse(courseData)
    }} className='course_page'>
      <div className="left">
        <img src={courseData.image} alt="" />
      </div>
      <div className="right">
        <h5>{courseData.cat}</h5>
        <h4>{courseData.name}</h4>
        <p>{courseData.unit_num}units,{courseData.card_num}cards</p>
      </div>
    </div>
  )
}

export default Course
