export const coursesData = [
  {
    id:1,
    name:'General Surgery ',
    un_num:21,
    cat:'Surgery',
    img:require("../../../../../Assets/images/img1.png"),
    card_num:33,
  },
  {
    id:2,
    name:'General Surgery ',
    img:require("../../../../../Assets/images/img2.png"),
    cat:'Surgery',
    un_num:21,
    card_num:33,
  },
];
