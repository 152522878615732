import React, { useState } from 'react';
import Courses from './Courses/Courses';
import Units from './Units/Units';
import Topics from './Topics/Topics';
import Questions from './Questions/Questions';

const WrQues = () => {
  const [selectedUnit, setSelectedUnit] = useState({});
  const [pageName, setPageName] = useState('courses');
  return (
    <div>
      {pageName == 'courses' ? (
        <Courses setPageName={setPageName} />
      ) : pageName == 'units' ? (
        <Units setSelectedUnit={setSelectedUnit} setPageName={setPageName} />
      ) : pageName == 'topics' ? (
        <Topics unitData={selectedUnit} setPageName={setPageName} />
      ) : pageName == 'questions' ? (
        <Questions setPageName={setPageName} />
      ) : null}
    </div>
  );
};

export default WrQues;
