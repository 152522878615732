import React from 'react';
import './flashcard.css';
const FlashCard = ({ flashData, setPageName }) => {
  return (
    <div className="course">
      <div className="left">
        <img src={flashData.img} alt="" />
      </div>
      <div className="right">
        <h3>{flashData.cat}</h3>
        <h3>{flashData.name}</h3>
        <p>
          <span>{flashData.units_count} unit,</span>
          <span>{flashData.cards_count} lessons</span>
        </p>
      </div>
    </div>
  );
};

export default FlashCard;
