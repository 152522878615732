import React from 'react';
import './Question.css';
const Question = ({ setPageName, questionData }) => {
  console.log(questionData);
  return (
    <div className="question">
      <h4>
        <img
          src={require("../../../../../../Assets/images/twitty.png")}
          alt=""
        />
      </h4>
      <p>{questionData?.qus_name}</p>
    </div>
  );
};

export default Question;
