import React, { useState } from "react";
import "./style.css";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Autoplay,
} from "swiper/modules";
import SwiperCore from "swiper";

const Testominials = ({ ref }) => {
  const testominials = [
    {
      id: "cbe55c5d-36fa-4f05-9557-fcd4966187f4",
      name: "Dillon Davis",
      address: "613 Dickinson Run",
      jop: "Tanzania",
      opinion:
        "Incididunt non et quis labore officia eiusmod. Fugiat sint amet eiusmod laborum veniam tempor incididunt non amet duis.  ",
      rate: 4,
      phone: "886.282.3509 x0831",
      profileImage:
        "https://media.proprofs.com/images/QM/user_images/2503852/-Type-Of-Person-.webp",
    },
    {
      id: "36df51a8-8d4c-4d70-8818-d77b9f6bf215",
      name: "Dannie Hackett",
      address: "8449 Brenna Alley",
      jop: "Central African Republic",
      opinion:
        "Incididunt non et quis labore officia eiusmod. Fugiat sint amet eiusmod laborum veniam tempor incididunt non amet duis.  ",

      rate: 5,
      phone: "(786) 895-4923",
      profileImage:
        "https://media.proprofs.com/images/QM/user_images/2503852/-Type-Of-Person-.webp",
    },
    {
      id: "36df51a8-8d4c-4d70-8818-d77b9f6bf215",
      name: "Davis Hackenn",
      address: "8449 Brenna Alley",
      jop: "Central African Republic",
      opinion:
        "Incididunt non et quis labore officia eiusmod. Fugiat sint amet eiusmod laborum veniam tempor incididunt non amet duis.  ",

      rate: 3,
      phone: "(786) 895-4923",
      profileImage:
        "https://media.proprofs.com/images/QM/user_images/2503852/-Type-Of-Person-.webp",
    },
  ];
  return (
    <div id="testimonials" ref={ref}>
      <div className="breadCrumb">
        <h1 className="crumb">Reviews</h1>
        <h4>What Our Students Say ?!</h4>
        <p>Our Learners Opinions</p>
      </div>

      <div className="testominials">
        <Swiper
          // effect="coverflow"
          loop={true}
          // autoplay={true}
          // navigation={true}
          slidesPerView={1}
          speed={1200}
        >
          {testominials?.map((item, index) => {
            return (
              <SwiperSlide>
                <div className="opinion">
                  <div className="leftOpinion">
                    <img src={item?.profileImage} alt="" />
                    <h5 style={{ color: "var(--main-color)" }}>{item?.name}</h5>
                    <h6>{item?.jop}</h6>
                  </div>
                  <div className="rightOpinion">
                    <div className="stars">
                      {Array(item?.rate)
                        .fill(0)
                        .map((item) => {
                          return (
                            <span
                              className="stars"
                              style={{
                                display: "inline-block",
                                marginRight: "6px",
                              }}
                            >
                              <img
                                src={require("../../Assets/images/favourite.png")}
                                alt=""
                                style={{ width: "20px" }}
                              />
                            </span>
                          );
                        })}
                      <p style={{ color: "var(--main-color)" }}>
                        {item?.opinion}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Testominials;
