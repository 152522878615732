/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import Features from "./layouts/Features";
import Home from "./layouts/Home";
import DefaultLayout from "./layouts/default";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Screens from "./layouts/screens";
import Preview from "./layouts/preview";
import Testominials from "./layouts/testomials";
import { useEffect, useRef, useState } from "react";
import Whyus from "./layouts/whyus";
import DownloadApp from "./layouts/downloadApp";
import FAQ from "./layouts/Faq";

function App() {
  const componentRef = useRef();
  const [component, setComponent] = useState(null);
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.4,
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      console.log(entry);
      if (entry.isIntersecting) {
        setComponent(entry);
      }
    });
  };
  useEffect(() => {
    console.log(component);
  }, [component]);
  return (
    <>
      <DefaultLayout
        children={
          <>
            <Home ref={componentRef} />
            <Features ref={componentRef} />
            <Screens ref={componentRef} />
            <Preview ref={componentRef} />
            <Testominials ref={componentRef} />
            <Whyus ref={componentRef} />
            <FAQ ref={componentRef} />
            <DownloadApp ref={componentRef} />
          </>
        }
      />
    </>
  );
}

export default App;
