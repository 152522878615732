export const questionsData=[
  {
    id:0,
    name:'..... is defined as nectrotic bone providing a nidus for infection in chronic osteomyelitie',
    ans:'sequestrum',
  },
  {
    id:1,
    name:'the first step in management',
    ans:'ABCDE approach',
  },
  {
    id:2,
    name:'..... is defined as nectrotic bone providing a nidus for infection in chronic osteomyelitie',
    ans:'sequestrum',
  },
  {
    id:3,
    name:'the first step in management',
    ans:'ABCDE approach',
  },
]
