import React from 'react';
import './courses.css'
import '../../../Tweets/Tweets/TweetsCourses/tweetscourses.css';
// import { coursesData } from './data'
import Course from './Course/Course';
import { coursesData } from '../../../Tweets/Tweets/TweetsCourses/data';
const Courses = ({ setPageName }) => {
  return (
    <div className="tweetscourses">
      <h3>
        <img src={require("../../../../../Assets/images/inter.png")} alt="" />
        <h4>Casses</h4>
      </h3>
      <p>Select The Course For The Tweets</p>
      <div className="coursestweets">
        {coursesData.map((item, index) => {
          return (
            <Course key={index} courseData={item} setPageName={setPageName} />
          );
        })}
      </div>
    </div>
  );
};

export default Courses;
