/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { NavBarData } from '../../data/navbarData';
import "./style.css";
import { AndroidIcon, AppleIcon, Menu } from '../../Assets/svgIcons';
import { androidLink, iosLink } from '../../data/applicationLinks';

const Header = () => {
  const [scrollValue, setScrollValue] = useState(0);
  const [showNavBar, setShowNavBar] = useState(false);
  window.addEventListener("scroll", (e) => {
    if (window.scrollY > 20) {
      setScrollValue(window.scrollY);
    } else {
      setScrollValue(window.scrollY);
    }
  });
  return (
    <header className={scrollValue < 20 ? "header" : "header sticky"}>
      <nav>
        <a
          className="header_logo"
          href="#"
          style={{ textDecoration: "none", color: "black" }}
        >
          <img
            src="https://res.cloudinary.com/duovxefh6/image/upload/v1698845852/logo-sm.2f4a7f57177ec499b9af0e73359fe76f_cgmfhv.svg"
            alt=""
          />
          {/* <span>DR.Elmatary</span> */}
        </a>
        <ul className={showNavBar ? "header_links show" : "header_links"}>
          {NavBarData?.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  setShowNavBar(false);
                }}
              >
                <a href={item?.link}> {item?.label_en}</a>
              </li>
            );
          })}
          <li
            onClick={() => {
              setShowNavBar(false);
            }}
          >
            <a
              href={"https://academy.dr-elmatary.com/"}
              target="_blanck"
              className="btn btn-success"
              style={{ color: "white" }}
            >
              {" "}
              Academy Website
            </a>
          </li>
          <div className="header_download">
            <button
              className="btn btn-primary"
              onClick={() => (window.location.href = "#downloadApp")}
            >
              Download App
            </button>
            <div className="downloadModal">
              <a href={iosLink} target="_blanck">
                {AndroidIcon}
              </a>
              <a href={androidLink} target="_blanck">
                {AppleIcon}
              </a>
            </div>
          </div>
        </ul>

        <div className="header_download">
          <button
            className="btn btn-primary"
            onClick={() => (window.location.href = "#downloadApp")}
          >
            Download App
          </button>
          <div className="downloadModal">
            <a href={iosLink} target="_blanck">
              {AndroidIcon}
            </a>
            <a href={androidLink} target="_blanck">
              {AppleIcon}
            </a>
          </div>
        </div>
        <div
          className="menu"
          onClick={() => {
            setShowNavBar(!showNavBar);
          }}
        >
          {Menu}
        </div>
      </nav>
    </header>
  );
};

export default Header;
